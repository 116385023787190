import { createStore } from 'vuex'
import { localStoragePlugin } from '@/localstorage';

const language_code_to_name = {
  am:"Amharic",
  ar:"Arabic",
  az:"Azerbaijani",
  ber:"Berber",
  bn:"Bangla",
  cs:"Czech",
  de:"German",
  dv:"Maldivian",
  en:"English",
  es:"Spanish",
  fa:"Persian",
  fr:"French",
  ha:"Hausa",
  hi:"Hindi",
  id:"Indonesian",
  it:"Italian",
  ja:"Japanese",
  ko:"Korean",
  ku:"Kurdish",
  ml:"Malayalam",
  nl:"Dutch",
  no:"Norwegian",
  pl:"Polish",
  ps:"Pashto, Pushto",
  pt:"Portuguese",
  ro:"Romanian",
  ru:"Russian",
  sd:"Sindhi",
  so:"Somali",
  sq:"Albanian",
  sv:"Swedish",
  sw:"Swahili",
  ta:"Tamil",
  tg:"Tajik",
  th:"Thai",
  tr:"Turkish",
  tt:"Tatar",
  ug:"Uighur",
  ur:"Urdu",
  uz:"Uzbek"
}

export default createStore({
  state: {
    user: null, // User object
    user_avatar: null, // User object
    token: null, // JWT token
    isAuthenticated: false, // Authentication status
    edition_name_english:"en.ahmedali",
    edition_name_arbic:"Iskcon-uthmani",
    recent_chapters:[],
    recent_surahs:[],
    language_code_to_name:language_code_to_name
  },
  getters: {
    getUser:              (state) => state.user,
    getUserAvatar:        (state) => state.user_avatar,
    getToken:             (state) => state.token,
    isAuthenticated:      (state) => state.isAuthenticated,
    getEdition:           (state) => state.edition_name_english,
    getEditionArabic:     (state) => state.edition_name_arbic,
    getRSurahs:           (state) => state.recent_surahs,
    getRChapters:         (state) => state.recent_chapters,
    getLanguageFullName:  (state) => state.language_code_to_name,    
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USER_AVATAR(state, data) {
      state.user_avatar = data;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },

    SET_EDITIONAL(state, data) {
      state.edition_name_english = data;
    },
    
    SET_R_CHAPTERS(state, data) {
      state.recent_chapters.unshift(data);
      // this.conversation_data.unshift(response.data.data);
    },
    SET_R_SURAHS(state, data) {
      // state.edition_name = data;
      state.recent_surahs.unshift(data);
    },
    CLEAR_R_CHAPTERS(state) {
      state.recent_chapters = [];
    },
    CLEAR_R_SURAHS(state) {
      state.recent_surahs = [];
    },
  },
  actions: {
    logout({ commit }) {
      // Clear user data and token
      commit('SET_USER', null);
      commit('SET_USER_AVATAR', null);
      commit('SET_TOKEN', null);
      commit('SET_AUTHENTICATED', false);
    },
  },
  modules: {
  },
  plugins: [localStoragePlugin], // Use the localStoragePlugin to persist state
})
