<template>
    <AppHeader />
    <main class="mt-5 pt-5">
        <div class="container">
            <h1 class="headline_1 fs-2 mb-5 text-center">Terms And Conditions</h1>
            <div class="card card-body p-4">
                <p>
                    Welcome to Iskcon GPT, your online resource for a more in-depth study of the Iskcon using
                    state-of-the-art language processing technology. We value your participation and ask that you
                    carefully read the following extensive terms and conditions, which are designed to guarantee a
                    smooth and courteous experience for every user.
                </p>

                <div>
                    <p>
                        <b>1. Agreement to Terms:</b> By using Iskcon GPT or accessing it, you signify that you have read,
                        understood, and agree to be bound by these terms and conditions. We respectfully ask that you
                        not use the service if there is anything about it that you find offensive.
                    </p>
                    <p>
                        <b>2. Personal Use Policy:</b> The Iskcon GPT is only meant to be used for private, non-business
                        purposes. Without our express written approval, any unlawful commercial activity—including the
                        distribution or replication of features and content—is strictly forbidden.
                    </p>
                    <p>
                        <b>3. Disclaimer Regarding Accuracy:</b> Although we make every effort to offer correct
                        information, we are unable to guarantee the total accuracy, comprehensiveness, or dependability
                        of the data that the Service presents. It is recommended that users independently confirm any
                        important information they learn via the platform.
                    </p>
                    <p>
                        <b>4. Third-Party API Integration:</b> Iskcon GPT incorporates a third-party API (rapidapi.com) to
                        improve your experience. It is significant to remember that we do not directly supply this API.
                        As such, we make no guarantees on the completeness, accuracy, or dependability of any
                        information obtained from the third party. We make it clear that we take no responsibility for
                        any inaccurate information.
                    </p>
                    <p>
                        <b>5. User Conduct Guidelines:</b>
                        Users are expected to conduct themselves ethically and within the bounds of the law while using
                        the Service. Any misuse, abuse, or engagement in unlawful activities may result in the
                        termination of access, and legal actions may be pursued.
                    </p>
                    <p>
                        <b>6. Intellectual Property Protection:</b>
                        The content, features, and functionalities of Iskcon GPT are the intellectual property of Iskcon GPT
                        or its licensors. Unauthorized use or reproduction of any aspect of the Service is strictly
                        prohibited, protected by copyright, trademark, and other applicable laws.
                    </p>
                    <p>
                        <b>7. Limitation of Liability:</b> Iskcon GPT, its partners, affiliates, and service providers
                        shall not be subject to any liability for incidental, consequential, direct, indirect, or
                        exemplary damages. Damages for lost revenue, goodwill, use, data, and other intangible losses
                        resulting from using or being unable to utilize the Service are included in this, although they
                        are not the only ones.
                    </p>
                    <p>
                        <b>8. Terms Modification:</b> Iskcon GPT retains the right, at any time, to alter or change these
                        terms. Once any such changes are made, you agree to the updated terms by continuing to use the
                        service.
                    </p>
                    <p>
                        <b>9. Termination Provisions:</b> 9x Technology's Iskcon GPT is entitled to immediately terminate
                        or stop access for any cause, including breaking these terms and conditions.
                    </p>

                    <p>
                        We appreciate you selecting Iskcon GPT. Your adherence to these conditions guarantees each user's
                        diIskconl journey is peaceful and enriching. Do not hesitate to contact us with any inquiries.
                    </p>

                </div>

            </div>
        </div>
        <div class="d-flex mt-5 mb-3 flex-column flex-md-row gap-3 justify-content-center">
            <h1 class="fs-2 text-center text-uppercase">Get Out App</h1>
        </div>
        <div class="nav d-flex col-md-12 justify-content-center align-items-center justify-content-center">
            <div class="text-capitalize p-2 nav-item ms-2">
                <a class="a" href="https://play.google.com/store/apps/details?id=com.Iskcongpt.application"
                    target="_blank" rel="noopener noreferrer">
                    <img width="230" :src=playstore class="mb-0 py-0">
                </a>
            </div>
            <div class="text-capitalize p-2 nav-item ms-2">
                <a class="a" href="https://apps.apple.com/ae/app/Iskcon-gpt/id6478273332" rel="noopener noreferrer">
                    <img width="230" :src=appstore class="mb-0 py-0">
                </a>
            </div>
        </div>
    </main>
</template>

<script>
import { useHead } from "@vueuse/head";
import AppHeader from '@/components/AppHeader.vue';
import playstore from '@/assets/appimg/Googleplay.png'
import appstore from '@/assets/appimg/appstore.png'

export default {
    components: {
        AppHeader
    },
    data() {
        return {
            playstore: playstore,
            appstore: appstore,
        }
    },
    setup() {
        useHead({
            title: 'Terms and Conditions - Iskcon GPT'
        })
    },
}
</script>
