<template>
  <nav class="fixed-top navbar navbar-default">
    <div class="container-fluid">
      <div class="row py-3 w-100">
        <div class="col-8 col-md-4 d-flex justify-content-start"></div>
        <div class="col-4 col-md-8 d-flex justify-content-end">
          <div class="ml-auto">
            <div class="hamburger">
              <a class="btn hamIcon" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                aria-controls="offcanvasExample">
                <i style="color:#BE4232;" class="fs-4 bi bi-list"></i>
              </a>

              <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel">
                <div class="offcanvas-header">
                  <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
                  <button type="button" ref="closeButton" class="btn-close" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
                </div>
                <div class="text-center ">
                  <div v-if="$store.getters.isAuthenticated">
                    <i class="bi bi-person-circle display-1"></i>
                    <hr>
                    <h5 class="mb-0 mt-0">{{ $store.getters.getUser.first_name }} {{ $store.getters.getUser.last_name }}
                    </h5>
                    <p class="mb-0 text-secondary small">{{ $store.getters.getUser.email }}</p>
                    <div class="mx-5">
                      <a class="button py-3 px-2 mt-3 w-100" @click="logout()">
                      Log Out <i class="bi bi-box-arrow-right"></i>
                    </a>
                    </div>
                    <hr>
                  </div>
                  <div v-else>
                    <i class="bi bi-person-circle display-1"></i>
                    <hr>
                    <a href="#" @click="closeOffcanvas" data-bs-toggle="modal" data-bs-target="#authModal"
                      class="fw-bold text-decoration-none text-dark">Login</a>
                    <hr>
                  </div>
                </div>
                <div style="scrollbar-width: none;" class="offcanvas-body p-0">
                  <div class="sidebarborder px-5 py-4 gurugranth_Button d-flex flex-column justify-content-center">
                    <button style="width: max-content;" class="w-100 py-3 text-center" @click="pageRoute('/chapter')"
                      :class="{ active: $route.name === 'Chapter', 'button': $route.name !== 'Chapter' }">Chapters Of
                      Iskcon</button>
                  </div>

                  <div class="px-5 pt-4 gurugranth_Button d-flex my-3 flex-column justify-content-center gap-3">
                    <div class="w-100 text-center" @click="pageRoute('/')"
                      :class="{ 'headline_1': $route.name === 'Landing', 'headline_2': $route.name !== 'Landing' }">Home
                    </div>
                    <div class="w-100 text-center" @click="pageRoute('/about')"
                      :class="{ 'headline_1': $route.name === 'AboutUsVue', 'headline_2': $route.name !== 'AboutUsVue' }">
                      About us</div>
                    <div class="w-100 text-center" @click="pageRoute('/terms-and-conditions')"
                      :class="{ 'headline_1': $route.name === 'TermsAndConditions', 'headline_2': $route.name !== 'TermsAndConditions' }">
                      Terms And Condition</div>
                    <div class="w-100 text-center" @click="pageRoute('/privacy-policy')"
                      :class="{ 'headline_1': $route.name === 'PrivacyPolicy', 'headline_2': $route.name !== 'PrivacyPolicy' }">
                      Privacy Policy</div>
                    <a target="blank" class="headline_2 w-100 text-center"
                      href="https://9xtechnology.com/contact.php">Contact Us</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AppHeader',
  components: {},

  data() {
    return {
      isScrolled: false,
    };
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY >= 60) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
    logout() {
        // this.$router.push({ name: 'LoginView' })
        this.$store.dispatch('logout') 
        location.reload()
    },
    closeOffcanvas() {
      this.$refs.closeButton.click();
    },
    pageRoute(link) {
      this.closeOffcanvas(); // Close offcanvas before navigating
      this.$router.push(link);
    },
  },
};
</script>
<style>
.hamIcon {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: rgb(249, 232, 224);
  border-radius: 50%;
  background: #f9e8e0
}

/* Header pages started */
.scrolled {
  background-color: var(--template-color-0);
  transition: background-color 0.3s ease;
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);
}

.loginText {
  color: var(--template-color-23);
}

.userDetails {
  cursor: pointer;
}

.gurugranth_Button>div {
  cursor: pointer;
}

.sidebarborder {
  /* border-top: 1px solid #999; */
  border-bottom: 1px solid #999;
}

.headline_2 {
  color: #999;
  text-decoration: none;
  font-family: sans-serif;
  line-height: 1;
  font-size: clamp(20px, 4vw, 20px);
}

.headline_1 {
  color: #BE4232;
  text-decoration: none;
  font-family: sans-serif;
  line-height: 1;
  font-size: clamp(20px, 4vw, 20px);
}

/* Header pages ended */
</style>