<template>
  <div class="card h-100 page_card p-0 m-0">
    <div class="card-header border-0 p-0 m-0">
      <div class="container-xxl">
        <div class="row">
          <div class="col-12 col-md align-self-center order-0 py-2 py-md-0 order-3 order-md-2">
            <div class="d-flex justify-content-center align-items-center">
              <div class="d-flex align-items-center">
                <div class="card-number text-center mb-0 me-2">{{ fetchedData.chapter_number }}</div>
                <h3 class="text-start mb-0">{{ fetchedData.name }}</h3>
                <p class="mb-0"><small>{{ fetchedData.translation }}</small></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TextToSpeech ref="textReader" class="mt-2"></TextToSpeech>
    </div>
    <div class="card-body overflow-y-scroll p-0">
      <div class="d-flex justify-content-center mt-2" id="top">
        <nav aria-label="breadcrumb" class="text-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Landing' }">Home</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'Chapter' }">Chapters</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Chapter {{ chapter_number }}</li>
          </ol>
        </nav>
      </div>
      <div class="container-xxl pb-5">
        <div v-if="loading">
          <div class="row justify-content-md-center">
            <div class="col-md-4">
              <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100"
                aria-valuemin="0" aria-valuemax="100" style="height: 30px">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" style="width: 100%">
                  Loading</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="mb-2 row g-3">
            <div class="col-md-4 text-center text-md-start">
              <div class="d-flex flex-column flex-md-row align-items-center">
                <img :src="krishna" class="m-3 krishnaImage" width="150">
                <div>
                  <h3 class="h2 mb-0">{{ fetchedData.name }}</h3>
                  <p>{{ fetchedData.name_transliterated }}</p>
                  <div class="d-flex gap-3 justify-content-center">
                    <a class="button py-2 px-2 px-md-4" @click="prevPage()" v-if="this.chapter_id > 1"><span><i
                          class="bi bi-chevron-left"></i> <span>Prev</span></span></a>
                    <a class="button py-2 px-2 px-md-4" @click="nextPage()"
                      v-if="this.chapter_id < 18"><span><span>Next</span> <i class="bi bi-chevron-right"></i></span></a>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-md text-center text-md-end">
              {{ mySummary }}
              <details>
                <summary class="p-2 fw-bolder ">
                  Summary in Hindi
                  <button class="btn btn-warning text-white"
                    @click="readText(fetchedData.chapter_summary_hindi, 'hi')"><i
                      class="bi bi-megaphone-fill"></i></button>
                </summary>
                <p id="mySummary" ref="mySummary">{{ fetchedData.chapter_summary_hindi }}</p>
              </details>
              <details>
                <summary class="p-2 fw-bolder ">
                  Summary in English
                  <button class="btn btn-warning text-white" @click="readText(fetchedData.chapter_summary, 'en')"><i
                      class="bi bi-megaphone-fill"></i></button>
                </summary>
                <p id="mySummary" ref="mySummary">{{ fetchedData.chapter_summary }}</p>
              </details>

            </div>
          </div>
          <div class="d-flex flex-column flex-md-row justify-content-between gap-3 py-3">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <h3 class="text-center mt-3">Verse</h3>
              <button style="width:max-content ;" class=" btn btn-warning text-white"
                @click="playVoice(fetchedData.chapter_audio)"><i class="bi bi-music-note-beamed"></i> Play</button>

            </div>
            <div>
              <div class="d-flex justify-content-between gap-3 py-3">
                <div v-if="translation_switch">
                  <label class="text-end w-100"><b>Languages</b></label>
                  <select class="form-control text-capitalize" v-model="selected_language_id"
                    @change="changeLanguage()">
                    <option disabled selected value="">Change Language</option>
                    <option v-for="lan in languages" :key="lan" :value="lan.id">
                      <span class="fw-bold">{{ lan.language_name }}</span>
                    </option>
                  </select>
                </div>
                <div v-if="translation_switch">
                  <label class="text-end w-100"><b>Authors</b></label>
                  <select class="form-control text-capitalize" v-if="authors" v-model="selected_author_id" @change="changeAuthor()">
                    <option disabled selected value="">Change Author</option>
                    <option v-for="auth in authors" :key="auth" :value="auth.id">
                      <span class="fw-bold">{{ auth.author_name }}</span>
                    </option>
                  </select>
                </div>

              </div>
            </div>
          </div>

          <div v-if="loading_sloks">
            <div class="row justify-content-md-center">
              <div class="col-12">
                <loadingOne />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="col-md-12" v-for="slok in sloks" :key="slok">
              <div class="ayat_card bg-light">
                <div class="card-header ">
                  Shlok: {{ slok.verse_number }}
                </div>
                <div class="card-body">
                  <div class="d-flex align-items-top flex-column">
                    <!-- <div class="card-number text-center m-0 me-2">{{ slok.verse_number }}</div> -->
                    <div class="w-100 text-center border-bottom mb-3 pb-2">
                      <h3 class="m-0 notranslate sanskrit">{{ (slok.text).replace(/\d+(\.\d+)?।।/, "") }}</h3>
                      <p class="transliteration notranslate h5 my-3">{{ slok.transliteration }}</p>
                    </div>
                    <div class="text-center">
                      <blockquote class="languageMeaning px-lg-4 px-0 fw-light">{{ (slok.word_meanings).replace(/[|।:0-9.-]/g, '') }}</blockquote>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-6 col-md-6">
                      <div class="text-start">
                        <button class="btn btn-warning text-white"
                          @click="readText((slok.text).replace(/[|।:0-9.]/g, ''), 'hi')"><i
                            class="bi bi-volume-up"></i><span> Sanskrit</span></button>
                      </div>
                    </div>
                    <div class="col-6 col-md-6">
                      <div class="text-end">
                        <button class="btn btn-warning text-white" ref="mySlok"
                          @click="readText((slok.word_meanings).replace(/[|।:0-9.]/g, ''), (slok.language_code))"><i
                            class="bi bi-volume-up"></i><span> Meaning</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="d-flex gap-3 justify-content-center">
              <a class="button py-2 px-2 px-md-4" @click="prevPage()" v-if="this.chapter_id > 1"><span><i
                    class="bi bi-chevron-left"></i> <span>Prev</span></span></a>
              <a class="button py-2 px-2 px-md-4" @click="nextPage()"
                v-if="this.chapter_id < 18"><span><span>Next</span> <i class="bi bi-chevron-right"></i></span></a>
            </div>

            <div class="text-center mt-3">
              <a href="#top" class="text-decoration-none button py-2 px-5"><i class="bi bi-caret-up-square-fill"></i>
                Back to Top</a>
            </div>
          </div>


        </div>
      </div>
    </div>
    <div class="card-footer p-0">
      <div class="container-xxl text-center">
        <!-- <TextToSpeech ref="textReader"></TextToSpeech> -->
        <!-- <div class="d-flex gap-3 align-items-center justify-content-center ">
            <div class="d-flex gap-3 justify-content-center">
              <a class="button py-2 px-2 px-md-4" @click="prevPage()" v-if="this.chapter_id > 1"><span><i class="bi bi-chevron-left"></i> <span>Prev</span></span></a>
              <a class="button py-2 px-2 px-md-4" @click="nextPage()" v-if="this.chapter_id < 18"><span><span>Next</span> <i class="bi bi-chevron-right"></i></span></a>
            </div>
          </div> -->
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import krishna from '../assets/krishna.png';
import TextToSpeech from '@/components/TextToSpeech.vue';
import loadingOne from '@/components/loader/Skeleton.vue';
import $ from 'jquery';

export default {
  name: 'ChapterDetails',
  components: {
    TextToSpeech,
    loadingOne
  },
  props: {
    chapter_id: String,
  },
  data() {
    return {
      krishna,
      showOffcanvas: false,
      sloks: [],
      fetchedData: [],
      languages: [],
      authors: [],
      selected_language_id: 1,
      selected_author_id: 1,
      chapter_number: this.chapter_id,
      slok_number: 1,
      loading: true,
      loading_sloks: true,
      play_voice: "",
      default_language: 'en',
      mySummary: "",
      translation: "Shri Purohit Swami",
      commentary: 0,
      translation_switch: true,
      commentary_switch: false
    };
  },
  watch: {
    chapter_number(newValue, oldValue) {
      console.log(oldValue)
      this.updatedValue = newValue;
    }
  },
  // created() {
  //   this.fetchData();   
  //   // this.fetchSlokData();
  // },
  activated() {
    this.changeChapterNumber(this.chapter_id);
    this.fetchData();
    this.fetchSlokData();
    this.fetchLanguages();
    this.fetchAuthors();
  },
  methods: {
    closePopup() {
      this.$swal.close();
    },
    titleToSlug(title) {
      return title.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-');
    },
    changeChapterNumber(chapter_number) {
      this.chapter_number = chapter_number;
    },
    stripTags(content) {
      let regex = /(<([^>]+)>)/ig;
      return content.replace(regex, "");
    },
    playVoice(url) {
      if (this.$store.getters.isAuthenticated) {
        this.play_voice = url;
        // this.$refs.controlPlayer.play(); // Call the child component's function
        this.$refs.textReader.readText("text", "language", this.play_voice);
      }
      else {
        this.$swal.fire({
          title: "<strong>Kindly log in to proceed.</strong>",
          icon: "info",
          html: `           
              For full access to all features, please log in or register.
              <br>
              <br>
      <a href="#" data-bs-toggle="modal" data-bs-target="#authModal" class="button">Login</a>
            `,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          showConfirmButton: false,

        })
      }

    },

    readText(text, language) {
      if (this.$store.getters.isAuthenticated) {
        this.$refs.textReader.readText(text, language); // Call the child component's function

      } else {
        this.$swal.fire({
          title: "<strong>Kindly log in to proceed.</strong>",
          icon: "info",
          html: `           
              For full access to all features, please log in or register.
              <br>
              <br>
      <a href="#" data-bs-toggle="modal" data-bs-target="#authModal" ref="button" class="button" @click="closePopup">Login</a>
            `,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          showConfirmButton: false,
        });
      }
    },
    pageback() {
      this.chapter_number = parseInt(this.chapter_id) - 1
      this.$router.go(-1);
      this.fetchData();
    },
    changeLanguage() {
      if (this.$store.getters.isAuthenticated) {
        this.fetchAuthors()
      }
      else {
        this.$swal.fire({
          title: "<strong>Kindly log in to proceed.</strong>",
          icon: "info",
          html: `           
              For full access to all features, please log in or register.
              <br>
              <br>
      <a href="#" data-bs-toggle="modal" data-bs-target="#authModal" ref="button" class="button" @click="closePopup">Login</a>
            `,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          showConfirmButton: false,
        });
      }
    },
    changeAuthor() {
      if (this.$store.getters.isAuthenticated) {

        this.fetchSlokData()
      }
      else {
        this.$swal.fire({
          title: "<strong>Kindly log in to proceed.</strong>",
          icon: "info",
          html: `           
              For full access to all features, please log in or register.
              <br>
              <br>
      <a href="#" data-bs-toggle="modal" data-bs-target="#authModal" ref="button" class="button" @click="closePopup">Login</a>
            `,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          showConfirmButton: false,

        });
      }

    },
    async fetchLanguages() {
      this.languages = [];
      try {
        let url = `${process.env.VUE_APP_ROOT_API_DOMAIN}/gita/languages/`
        const options = {
          method: 'GET',
          url: url
        };

        const response = await axios(options);
        this.languages = response.data.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async fetchAuthors() {
      this.authors = [];
      try {
        let url = `${process.env.VUE_APP_ROOT_API_DOMAIN}/gita/authors/`

        // Append the query parameter to the URL
        url += `?language_id=${this.selected_language_id}`;

        const options = {
          method: 'GET',
          url: url
        };

        const response = await axios(options);
        this.authors = response.data.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async fetchData() {
      this.loading = true;
      this.loading_sloks = true;
      this.showOffcanvas = false;
      this.sloks = [];
      try {
        let url = `${process.env.VUE_APP_ROOT_API_DOMAIN}/gita/chapter/${this.chapter_number}/`
        const options = {
          method: 'GET',
          url: url
        };

        const response = await axios(options);
        this.fetchedData = response.data.data;
        this.$router.replace({ params: { chapter_name: this.titleToSlug(this.fetchedData.name_translated) } });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      this.loading = false;
    },
    async fetchSlokData() {
      this.loading_sloks = true
      return new Promise((resolve) => {
        this.loading_sloks = true
        console.log(` chapter_id for item ${this.chapter_number}`);
        setTimeout(async () => {
          try {
            let url = `${process.env.VUE_APP_ROOT_API_DOMAIN}/gita/chapter/${this.chapter_number}/verses/`

            // Append the query parameter to the URL
            if (this.selected_author_id != "") {
              url += `?author=${this.selected_author_id}`;
            }

            const options = {
              method: 'GET',
              url: url
            };

            const response = await axios(options);
            this.sloks = response.data.data;
            this.loading_sloks = false;
            resolve();
          } catch (error) {
            console.error('Error fetching data:', error);
            this.loading_sloks = false;
          }
        }, 200);
      });
    },
    readSummaryText(event) {
      console.log(event);
      const text = $(this.$refs.mySummary).text();
      this.default_language = document.documentElement.lang;
      this.$refs.textReader.readText(text, this.default_language); // Call the child component's function
    },
    readSlokText(event) {
      const text = $(event.currentTarget).parent().parent().parent().parent().parent().find('p').text();
      this.default_language = document.documentElement.lang;
      this.$refs.textReader.readText(text, this.default_language); // Call the child component's function
    },

    prevPage() {
      this.changeChapterNumber(parseInt(this.chapter_id) - 1);
      this.$router.push({ name: 'ChapterDetailsView', params: { chapter_id: this.chapter_number } })
      this.fetchData();
      this.fetchSlokData();
    },
    nextPage() {
      this.changeChapterNumber(parseInt(this.chapter_id) + 1);
      this.$router.push({ name: 'ChapterDetailsView', params: { chapter_id: this.chapter_number } })
      this.fetchData();
      this.fetchSlokData();
    },
    navigateToslok(slok_number) {
      slok_number = parseInt(slok_number)
      this.fetchSlokData(this.chapter_number, slok_number);
    },
    addAsRecentViewChapter(fetchedData) {
      let getRChapters = this.$store.getters.getRChapters
      if (getRChapters.filter(item => item.chapter_number == fetchedData.chapter_number).length == 0) {
        // alert("its unique")
        this.$store.commit('SET_R_CHAPTERS', fetchedData);
      }
      else {
        // alert("its not unique")
      }
    },
    removeFirstChars(inputString, length) {
      if (inputString.length >= length) {
        return inputString.slice(length);
      } else {
        // Handle cases where the string is shorter than 3 characters
        return "String is too short to remove three characters";
      }
    },
    removeLastChars(inputString, length) {
      if (inputString.length >= length) {
        return inputString.substring(0, inputString.length - length);
      } else {
        // Handle cases where the string is shorter than 3 characters
        return "String is too short to remove three characters from the end";
      }
    }
  }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tiro+Devanagari+Sanskrit:ital@0;1&display=swap');


.page_card {
  background: #F4F4F4 url("../assets/book-bg.png") top center no-repeat !important;
  background-size: cover !important;
}

.page-border-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  background: url("../assets/border2.png") center;
  background-size: contain;
  height: 30px;
  z-index: 10;
}

.sanskrit {
  font-family: "Tiro Devanagari Sanskrit", serif;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(22px, 4vw, 28x);

}

@media only screen and (max-width: 600px) {
  #wrapper {
    background: #F4F4F4 url("../assets/books.png") bottom center no-repeat;
    background-size: cover;
  }

  .page-border-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    background: url("../assets/border2.png") center repeat-x;
    background-size: contain;
    height: 30px !important;
    z-index: 10;
  }
}




main {
  border-radius: 6px;
  border: 1px solid #E3B232;
  background: #F8F8F8;
  position: relative;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  height: 100dvh;
  width: 100%;
}

body {
  overflow: hidden !important;
}

@media only screen and (max-width: 600px) {
  main .card {
    padding: 30px 0;
  }

  main .page-border-top {
    background-size: contain;
    height: 40px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  main .page-border-bottom {
    background: url("../assets/border2.png") center repeat-x;
    background-size: contain;
    height: 40px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  main .page-border-left {
    display: none;
  }

  main .page-border-right {
    display: none;
  }
}

.languageMeaning {
  color: #d52527;
  font-size: clamp(16px, 4vw, 22px);
  font-weight: 600;
  line-height: 1.3;
}

.transliteration {
  font-size: 16px;
  color: #6c757d;
  font-weight: 400;
  font-style: italic;
}

div:where(.swal2-container).swal2-backdrop-show,
div:where(.swal2-container).swal2-noanimation {
  background: rgba(0, 0, 0, .4);
  z-index: 999;
}

.swal2-popup,
.swal2-modal,
.swal2-icon-info,
.swal2-show {
  z-index: 99999 !important;
}
</style>