<template>
  <form autocomplete="off" autosave="off" @submit.prevent="registerAction()">

    <div class="mb-3">
      <label for="name" class="form-label">First Name</label>
      <input type="text" class="form-control form-control-lg " id="name" v-model="registerUser.first_name">
      <span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.first_name.$error">
  {{ v$.registerUser.first_name.$errors[0].$message }}
</span>
    </div>
    <div class="mb-3">
      <label for="last_name" class="form-label">Last Name</label>
      <input type="text" class="form-control form-control-lg " id="last_name" v-model="registerUser.last_name">
      <span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.last_name.$error">
        {{ v$.registerUser.last_name.$errors[0].$message }} </span>
    </div>
    <div class="mb-3">
      <label for="email" class="form-label">Email </label>
      <input type="email" class="form-control form-control-lg " id="email" v-model="registerUser.email">
      <span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.email.$error"> {{
        v$.registerUser.email.$errors[0].$message }} </span>
    </div>
    <div class="mb-3 d-flex align-items-start flex-column">
      <div>

        <label for="phone" class="form-label">Phone </label>
      </div>
      <div class="d-flex gap-3 w-100">
      <select style="width: 80px;font-size: 12px" class="py-3 form-select form-control form-focus text-secondary"
        id="countryCode" v-model="registerUser.mobile_country_code" @change="updateCountryCode">
        <option style="font-size: 14px;" v-for="(country, index) in sortedContrycode" :key="index" :value="country.dial_code">
          {{ country.code }} {{country.dial_code }}
        </option>
      </select>
      
      <input type="tel" class="w-100 form-control form-control-lg " id="email" v-model="registerUser.mobile">
     
      
      </div>
      <div><span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.mobile.$error"> {{
        v$.registerUser.mobile.$errors[0].$message }} </span></div>
    </div>
    <div class="mb-3">
      <label for="password" class="form-label">Password</label>
      <div class="position-relative mt-3">
        <input :type="showPassword ? 'text' : 'password'" class="form-control form-control-lg " id="password" v-model="registerUser.password">
              <button @click="togglePassword" type="button"
                class="btn btn-link position-absolute end-0 top-50 translate-middle-y">
                <i class="text-secondary" :class="showPasswordtwo ? 'bi bi-eye-slash' : 'bi bi-eye-fill'"></i>
              </button>
            </div>
      <span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.password.$error"> {{
        v$.registerUser.password.$errors[0].$message }} </span>
    </div>
    <!-- <div class="mb-3">
      <label for="promo_code" class="form-label">Promo Code</label>
      <input type="text" class="form-control form-control-lg " id="promo_code" v-model="registerUser.promo_code">
    </div>
    
    <div class="mb-3">
      <input type="checkbox" class="form-check-input" id="lucky_draw" v-model="registerUser.lucky_draw" :true-value="'1'" :false-value="'0'">
      <label for="lucky_draw" class="form-label ms-2">Participate in lucky draw for umrah</label>
    </div> -->
    
    <div class="d-grid gap-3 text-center align-items-center justify-content-center" v-if="!loading">
          <button class="button py-2 px-5 mt-3" type="submit">Sign in</button>
          <!-- <button class="theme-button-outline" type="button"><img src="../../assets/google.svg" height="20"> Sign in with Google</button> -->
          </div>
          <div class="d-grid mt-4 gap-3 text-center align-items-center" v-if="!loading">
          <GoogleAuth></GoogleAuth>
          <small>By creating an account you agree to Terms and Conditions</small>

          </div>
  </form>
</template>
<style scoped>
.navbar {
  display: none !important;
}
</style>
<script>
import swal from 'sweetalert';
import useVuelidate from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import axios from 'axios';
import Contrycode from '@/assets/country_codes.json';
import GoogleAuth from "@/components/GoogleAuth.vue";

export default {
  name: "RegisterView",
  components: {
    GoogleAuth,
  },
  data() {
    return {
      showPassword: false,
      Contrycode: Contrycode,
      api_base_url: process.env.VUE_APP_ROOT_API,
      registerUser: {
        first_name: '',
        last_name: '',
        email: '',
        mobile_country_code: '+91',
        mobile: '',
        password: '',
        promo_code: '',
        lucky_draw: '', // Set to string '1' initially
      }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  // mounted() {
  //   console.log(this.Contrycode);
  // },
  validations() {
    return {
      registerUser: {
        email: {
          required: helpers.withMessage('Email is required', required),
        },
        first_name: {
          required: helpers.withMessage('First name is required', required),
        },
        last_name: {
          required: helpers.withMessage('Last name is required', required),
        },
        mobile: {
          required: helpers.withMessage('Mobile number is required', required),
        },
        password: {
          required: helpers.withMessage('Password is required', required),
          minLength: helpers.withMessage('Password must be at least 8 characters long', minLength(8)),
        },
      }
    }
  },
  computed: {
    sortedContrycode() {
      // Create a copy of the Contrycode array using slice()
      const sortedArray = this.Contrycode.slice();

      // Sort the sortedArray based on the dial_code property
      return sortedArray.sort((a, b) => {
        // Extract the numerical part of the dial_code
        const numA = parseInt(a.dial_code.replace('+', ''));
        const numB = parseInt(b.dial_code.replace('+', ''));
        // Compare the numerical values
        return numA - numB;
      });
    }
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    updateCountryCode(event) {
            this.registerUser.mobile_country_code = event.target.value;
        },
        async registerAction() {
  this.v$.registerUser.$touch();
  if (!this.v$.registerUser.$invalid) {
    this.registerUser.register_error = false;
    console.log("validated");
    this.registerUser.lucky_draw = String(this.registerUser.lucky_draw);

    // calling api
    const API_URL = `https://api.Iskcongpt.com/api/v1/user/register/`;
    const formData = axios.toFormData(this.registerUser);

    try {
      const response = await axios.post(API_URL, formData);
      console.log('hello, ' + response);

      if (response.status === 203) {
        swal({
          title: "error",
          text: response.data.message,
          icon: "error",
        });
      } else if (response.status === 201) {
        console.log(response, '200');
        swal({
          title: "success",
          text: response.data.message,
          icon: "success",
        });
        this.$store.commit('SET_USER', response.data.data.user);
        this.$store.commit('SET_TOKEN', response.data.data.token);
        this.$store.commit('SET_AUTHENTICATED', true);
        this.$store.commit('SET_USER_AVATAR', response.data.data.user.avatar);
        location.reload()
      }
    } catch (error) {
      console.log(error);
      if (error.response || error.response.status === 400) {
        let errorMessage = '';
        if (error.response.data.email) {
          errorMessage += `Email: ${error.response.data.email.join(', ')}\n`;
        }
        if (error.response.data.mobile) {
          errorMessage += `Mobile: ${error.response.data.mobile.join(', ')}`;
        }
        swal({
          title: "error",
          text: errorMessage || 'Bad request',
          icon: "error",
        });
      } else {
        swal({
          title: "error",
          text: 'An unexpected error occurred.',
          icon: "error",
        });
        console.error(error);
      }
    } finally {
      this.loading = false;
    }
  } else {
    console.log("not done");
    swal({
      title: "error",
      text: "Please fill the required fields",
      icon: "error",
    });
    this.registerUser.register_error = true;
  }
}

  }
}
</script>