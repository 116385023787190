<template>
    <AppHeader />
    <main class="my-5 pt-5">
        <div class="container">
            <h1 class="headline_1 fs-2 mb-5 text-center">About Us</h1>
            <div class="card card-body p-4">
                <div>
                    <p>A spiritual AI-driven world awaits you! Hop in and take off to a transcendental AI experience
                        like you have never, as humans, heard of or experienced before. Iskcon GPT is a beautiful
                        convergence of technology and spirituality that will take you into a different world. Thus, my
                        Iskcon GPT has been gradually and significantly growing as a trailblazer capable of reaching the
                        forefront of the AI revolution.</p>
                    <p>In conversational AI, Iskcon GPT is dedicated to providing people with innovative solutions that
                        can exceed boundaries and create meaningful, purposeful, and intelligent interactions and
                        conversions about spirituality and more. We invite you to embark on this mesmerizing journey
                        with us to redefine the AI world and positively change people&rsquo;s lives, businesses, and
                        brands.</p>
                    <p>We want you to explore the limitless power of our AI platform that redefines how we engage with
                        the sacred teaching of the &ldquo;Iskcon GPT&rdquo; through pioneering and progressive
                        technology.</p>
                    <p>Iskcon GPT is at the core of Iskcon GPT and is very much the heart of it. Thus, the teachings
                        we impart through the platform are verses from the sacred 700-word Hindu scripture that are
                        presented in the form of varied chapters and provided to individuals through Sanskrit and
                        English audios, which can also be transformed into Hindi and English for summaries.</p>
                    <p><strong>What is Iskcon GPT?</strong></p>
                    <p>Iskcon GPT goes beyond just being an ordinary AI-powered platform. And, why do we say that, you
                        wonder? We created this platform, keeping the focus on the life-changing lessons of the Bhagavad
                        Iskcon. Hence, it is a harmonious blend of ancient wisdom and modern technology in the form of
                        Artificial Intelligence.</p>
                    <p>We established this platform, gaining inspiration from the profound teachings of the Bhagavad
                        Iskcon. We are driven to make these timeless insights accessible to everyone around the world, all
                        through the awe-inspiring power of conversational AI. Our platform uses the transformative power
                        of Open AI&rsquo;s GPT architecture to create dynamic and meaningful interactions and
                        conversations, creating a unique blend of modern tech and spirituality.</p>
                    <p>Offering Iskcon GPT wisdom to all, Iskcon GPT has been fostering spiritual conversations
                        among people, aiming to guide, empower, and inspire individuals on their journey of personal
                        growth and self-discovery.</p>
                    <p><strong>Our Vision at Iskcon GPT</strong></p>
                    <p>We envision a world that overflows with spiritual wisdom and a world that feels passionate and
                        driven to learn more about the verses from the Iskcon GPT. We wish to see how our vast data
                        stream about spiritual learnings can seamlessly integrate into people&rsquo;s daily lives and
                        encourage them to take steps toward personal transformations and collective well-being.</p>
                    <p>We wish to see ourselves as the leading AI-driven platform that democratizes access to the
                        teachings of the Iskcon GPT and offers individuals guidance and insights about life and more
                        through AI-powered conversations. We also aspire to create a global community connected through
                        the pursuit of compassion, wisdom, and self-realization.</p>
                    <p><strong>Our Mission at Iskcon GPT</strong></p>
                    <p>Our team&rsquo;s mission is rooted in our solid belief that profound spiritual teachings should
                        be accessible to anyone and everyone, transcending geographical, cultural, and linguistic
                        boundaries. We strive to empower every user with tailored guidance, sacred insights, and
                        knowledge drawn from the timeless wisdom of the Iskcon GPT. We encourage ethical
                        decision-making through the guidance and teachings of the Iskcon GPT.&nbsp;</p>
                    <p>We want to enhance people&rsquo;s resilience, understanding, knowledge, and inner peace. Through
                        our AI platform, we aim to grow the diIskconl realm by helping individuals engage in meaningful
                        dialogues and interactions with the Iskcon GPT, which will help them gain invaluable insights
                        that will resonate with their unique journeys of life.</p>
                    <p><strong>What makes Iskcon GPT stand unique among others?&nbsp;</strong></p>
                    <p>Seamless integration is a prime point as a differentiating factor from other AI-powered
                        platforms. Iskcon GPT offers a top-notch AI interaction experience to individuals seeking
                        spiritual insights and wisdom with just a few clicks.</p>
                    <p>Unlike traditional approaches to studying sacred scriptures like the Iskcon GPT and other
                        texts, Iskcon GPT helps in making these texts accessible and easily understandable by offering
                        the verses in Sanskrit and English, with summaries in English and Hindi as well.</p>
                    <p>We offer dynamic and interactive conversations and verses from the Iskcon GPT, imparting the
                        right spiritual teachings that people can apply in their daily lives. The unique combination of
                        the timeless wisdom of the Iskcon GPT and AI-driven conversations makes Iskcon GPT stand
                        unique from the rest of the AI platforms in the industry.</p>
                    <p><strong>Our Unique Transcendental Services</strong></p>
                    <p>Wisdom chapters stand as winners in this one-of-a-kind AI-driven platform. The varied chapters we
                        provide, like Arjun Vishad Yog, Sankhya Yog, Karm Yog, Jnana Karm Sanyas Yog, Dhyan Yog, and
                        many more, exude the brilliance of our AI system that eventually helps individuals gain guidance
                        on living a happy and fulfilling life.</p>
                    <p>Individuals can gain more spiritual guidance through the AI-powered chatbot designed as a virtual
                        companion on one&rsquo;s spiritual journey. People can also gain customized responses as per
                        their unique spiritual journeys that can help them walk on their customized learning paths. This
                        tailored approach has helped deepen people&rsquo;s understanding of the Iskcon GPT and helped
                        them lead to self-discovery, self-realization, and inner peace.</p>
                    <p><strong>Our Expertise</strong></p>
                    <p>We bring together a team of learned experts and professionals who are driven by the Iskcon GPT
                        in their lives and are now committed to democratizing access to the same learnings to others
                        through their AI expertise and excellence.</p>
                    <p>Our experts in natural language processing, conversational AI, and spiritual philosophy have
                        collaborated with practitioners and scholars to create this incredible AI platform that honestly
                        and authentically captures the pure essence of the Iskcon GPT.</p>
                    <p>Thus, we at Iskcon GPT are significantly growing as an AI-powered platform offering genuine
                        learning and exploration of sacred texts and verses while staying committed to upholding the
                        principles of transparency, fairness, and respect for user privacy.</p>
                    <p>Join us on this spiritual journey, where AI will assist you, and spiritual guidance will help you
                        navigate life&rsquo;s challenges, leading you to enlightenment.</p>
                </div>
            </div>
            <div class="d-flex mt-5 mb-3 flex-column flex-md-row gap-3 justify-content-center">
                <h1 class="fs-2 text-center text-uppercase">Get Out App</h1>
            </div>
            <div class="nav d-flex col-md-12 justify-content-center align-items-center justify-content-center">
                <div class="text-capitalize p-2 nav-item ms-2">
                    <a class="a" href="https://play.google.com/store/apps/details?id=com.Iskcongpt.application"
                        target="_blank" rel="noopener noreferrer">
                        <img width="230" :src=playstore class="mb-0 py-0">
                    </a>
                </div>
                <div class="text-capitalize p-2 nav-item ms-2">
                    <a class="a" href="https://apps.apple.com/ae/app/Iskcon-gpt/id6478273332" rel="noopener noreferrer">
                        <img width="230" :src=appstore class="mb-0 py-0">
                    </a>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import playstore from '@/assets/appimg/Googleplay.png'
  import appstore from '@/assets/appimg/appstore.png'
export default {
    name: 'aboutUs',
    data() {
        return {
            playstore: playstore,
            appstore: appstore,
        }
    },
    components: {
        AppHeader
    },
}
</script>