<template>
    <div id="wrapper">
      <div class="container">
        <div class="row justify-content-center vh-100 align-items-center">
          <div class="col-sm-10 col-md-10 text-center">
            <h1 class="headline_1 mb-5">404 <span>Error</span></h1>
            <p class="mb-5">Page Not found</p>
            <SearchBar />
            <div class="d-flex gap-3 justify-content-center">
              <router-link class="button" :to="{ name:'Chapter' }">CHAPTER</router-link>
              <router-link class="button button_outline" :to="{ name:'Surah' }">SURAH</router-link>
              <router-link class="button" :to="{ name:'Editions' }">Editions</router-link>

            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      name: 'NotFoundView',
  }
  </script>

  