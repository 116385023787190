<template>
  <div id="wrapper">
    <AppHeader/>
    <div class="container">
      <div class="row justify-content-center align-items-center vh-100">
        <div class="col-sm-10 col-md-10 text-center">
          <SearchBar />
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper_bg">
    <RecentChapter></RecentChapter>
  </div>
  <div v-if="Object.keys(banners).length != 0" class="c_modal_box">
    <div class="c_model_box_wrapper">
      <div class="c_model_box_body">
        <a style="cursor: pointer;" class="close_button" @click="banners = {}"><i class="bi bi-x-lg"></i></a>
        <img :src="banners.file" class="w-100">
      </div>
    </div>
  </div>
</template>


<script>
import swal from 'sweetalert';
import SearchBar from '@/components/SearchBar.vue';
import RecentChapter from '@/components/RecentChapter.vue';
import AppHeader from '@/components/AppHeader.vue';
// import axios from 'axios';

export default {
    name: 'LandingView',
    data() {
      return {
        showOffcanvas: false,
        fetchedData: [],
        loading: true,
        recent_chapters:this.$store.getters.getRChapters,
        recent_surahs:this.$store.getters.getRSurahs,
        banners: {},
        settings: {
          itemsToShow: 1,
          snapAlign: 'center',
          gap: 10
        },
        breakpoints: {
          700: {
            itemsToShow: 2,
            snapAlign: 'center',
          },
          1024: {
            itemsToShow: 4,
            snapAlign: 'start',
          },
        },
      };
    },
    components: {
      SearchBar,
      RecentChapter,
      AppHeader
    },
    mounted() {
    },
    created() {
      this.getBanners()
    },
    methods: {
      async getBanners()
      {
        // try {
        //   const response = await axios.get(`${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/get_banners/1/`);
        //   this.banners = response.data.data;
        // } catch (error) {
        //   console.error('Error fetching data:', error);
        // }
      },
      clearAllChapters()
      {
        this.$store.commit('CLEAR_R_CHAPTERS');
        swal({
          title: "Success",
          text: "All recently chapter's history is cleard successfully",
          icon: "success",
        }).then(function() {
          location.reload();
        });
      },
      clearAllSurahs()
      {
        this.$store.commit('CLEAR_R_SURAHS');

        swal({
          title: "Success",
          text: "All recently surah's history was cleard successfully",
          icon: "success",
        }).then(function() {
          location.reload();
        });
      }
    }
}
</script>

<style>
.c_modal_box{
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
}
.c_model_box_wrapper{
  margin: auto;
  padding: 20px;
  max-width: 700px;
  position: relative;
}
.c_model_box_body{
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}
.c_model_box_body .close_button{
  position: absolute;
  color: white;
  background: white;
  color: red;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  margin: 10px;
  right: 0;
  top: 0;
}
.c_model_box_body .close_button:hover{
  transform: scale(1.2);
}
</style>

